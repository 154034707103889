import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SupportDto } from 'converters/support'
import { EntityStatus } from 'types/redux'
import { Maybe } from 'types/utils'

import { commonReducers } from './commonReducers'

interface State {
	chat: Maybe<SupportDto.Chat>
	contacts: Maybe<SupportDto.Contacts>
	manager: Maybe<SupportDto.Manager>
	status: EntityStatus
}

const initialState: State = {
	chat: null,
	contacts: null,
	manager: null,
	status: 'pending',
}

const supportSlice = createSlice({
	name: 'support',
	initialState,

	reducers: {
		setContacts: (state, action: PayloadAction<SupportDto.Contacts>) => {
			state.contacts = action.payload
		},

		setManager: (state, action: PayloadAction<SupportDto.Manager>) => {
			state.manager = action.payload
		},

		setChat: (state, action: PayloadAction<SupportDto.Chat>) => {
			state.chat = action.payload
		},

		setStatus: commonReducers.setStatus,
		reset: commonReducers.reset(initialState),
	},
})

const { actions: supportReducerActions, reducer } = supportSlice

export { supportReducerActions, reducer as supportReducer }
