import axios, { CancelToken } from 'axios'
import { support as supportConverter, SupportDto } from 'converters/support'

export namespace SupportApi {
	export type Contacts = SupportDto.Contacts
	export type Manager = SupportDto.Manager
}

export const support = {
	contacts: (cancelToken?: CancelToken) =>
		axios
			.get<SupportApi.Contacts>(`/v3/support/contacts`, {
				cancelToken,
			})
			.then((response) =>
				Object.assign({}, response, { data: supportConverter.contacts(response.data) })
			),

	manager: () =>
		axios
			.get<SupportApi.Manager>(`/v3/support/personal-manager`)
			.then((response) =>
				Object.assign({}, response, { data: supportConverter.manager(response.data) })
			),

	chat: () =>
		axios
			.get<SupportApi.Manager>(`/v3/support/chat-token`)
			.then((response) =>
				Object.assign({}, response, { data: supportConverter.chat(response.data) })
			),
}
